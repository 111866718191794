import React, { useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";

import { images } from "../../constants";
import "./NavBar.scss";

const NavBar = () => {
  const [toggle, setToggle] = useState(false);
  // const menuSelection = ["Accueil", "À propos", "Réalisations", "Compétences", "Contact"];
  // const menuSelection = ["Accueil", "À propos", "Compétences", "Contact"];
  const menuSelection = ["Accueil", "Compétences", "Contact"];

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <a href="#Accueil" aria-label="Redirect Accueil">
          <img src={images.logo} alt="logo" />
        </a>
      </div>
      <ul className="app__navbar-links">
        {menuSelection.map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <a href={`#${item}`} aria-label="Redirect menu">
              {item}
            </a>
          </li>
        ))}
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div whileInView={{ x: [300, 0] }} transition={{ duration: 0, ease: "easeOut" }}>
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {menuSelection.map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)} aria-label="Redirect menu">
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
