const monthsAbrev = [
  "Janv.",
  "Févr.",
  "Mars",
  "Avr.",
  "Mai",
  "Juin",
  "Juill.",
  "Aôut",
  "Sept.",
  "Oct.",
  "Nov.",
  "Déc.",
];

export default monthsAbrev;
