import React from "react";

import { About, Contact, Footer, Header, Skills, Work } from "./container";
import { NavBar, ScrollProgressBar } from "./components";
import "./App.scss";

const App = () => {
  return (
    <div className="app">
      <ScrollProgressBar />
      <NavBar />
      <Header />
      {/* <About /> */}
      {/* <Work /> */}
      <Skills />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
