import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";

import { FaMapPin } from "react-icons/fa";
import { urlFor, client } from "../../client";
import { monthsAbrev } from "../../constants";
import "./Skills.scss";

const Skills = () => {
  const [workExperience, setWorkExperience] = useState([]);
  const [skills, setSkills] = useState([]);

  function yearMonthDiff(dateStart, dateEnd) {
    const yearStart = parseInt(dateStart.substring(0, 4));
    const monthStart = parseInt(dateStart.substring(5, 7));
    const currentDate = new Date();
    let yearEnd = currentDate.getFullYear();
    let monthEnd = currentDate.getMonth() + 1;
    if (dateEnd !== undefined) {
      yearEnd = parseInt(dateEnd.substring(0, 4));
      monthEnd = parseInt(dateEnd.substring(5, 7));
    }
    const diffYear = yearEnd - yearStart;

    let totalMonth = 0;
    let totalYear = 0;
    let resultFinal = "";

    if (diffYear === 0) {
      totalMonth += monthEnd - monthStart + 1;
    } else {
      totalMonth += 12 - monthStart + 1;

      for (let i = 1; i < diffYear; i++) {
        totalMonth += 12;
      }

      totalMonth += monthEnd;
    }

    if (totalMonth > 12) {
      totalYear = Math.floor(totalMonth / 12);
      totalMonth = totalMonth % 12;
    }

    if (totalYear === 0) {
      if (totalMonth === 12) {
        resultFinal = "1 an";
      } else {
        resultFinal = totalMonth + " mois";
      }
    } else if (totalYear === 1) {
      resultFinal = totalYear + " an " + totalMonth + " mois";
    } else {
      resultFinal = totalYear + " ans " + totalMonth + " mois";
    }

    return resultFinal;
  }

  useEffect(() => {
    const query = '*[_type == "workExperience"]';
    const skillsQuery = '*[_type == "skills"]';

    client.fetch(query).then((data) => {
      let finalData = data.map((info) => {
        return { ...info, desc: info.desc[0].children[0].text.split("\n") };
      });

      setWorkExperience(finalData);
    });

    client.fetch(skillsQuery).then((data) => {
      setSkills(data);
    });
  }, []);

  return (
    <div id="Compétences" className="padding-container app__whitebg">
      <div className="app__block">
        <h2 className="head-text">
          Compétences <span>&</span> Expériences
        </h2>

        <div className="app__skills">
          <div className="app__skills-container">
            <motion.div className="app__skills-list">
              {skills.map((skill) => (
                <motion.div
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  whileHover={{ scale: 1.1 }}
                  className="app__skills-item app__flex"
                  key={skill.name}
                >
                  <div
                    className="app__flex"
                    style={{
                      backgroundColor: skill.bgColor ? skill.bgColor : "white",
                    }}
                  >
                    <img src={urlFor(skill.icon)} alt={skill.name} />
                  </div>
                  <p className="p-text">{skill.name}</p>
                </motion.div>
              ))}
            </motion.div>
            <div className="app__skills-exp"></div>
          </div>

          <div className="app__experience-container">
            <div className="timeline">
              <div className="outer">
                {workExperience
                  .sort(
                    (a, b) =>
                      parseInt(b.dateDebut.replace("-", "")) -
                      parseInt(a.dateDebut.replace("-", ""))
                  )
                  .map((work, index) => (
                    <div className="card" key={` work-${index}`}>
                      <motion.div
                        whileInView={{ y: [25, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.5 }}
                        className="info"
                      >
                        <div className="title">
                          {
                            monthsAbrev[
                            parseInt(work.dateDebut.substring(5, 7)) - 1
                            ]
                          }{" "}
                          {work.dateDebut.substring(0, 4)}
                          {work.dateFin
                            ? ` - ${monthsAbrev[
                            parseInt(work.dateFin.substring(5, 7)) - 1
                            ]
                            } ${work.dateFin.substring(0, 4)}`
                            : " - Aujourd'hui"}{" "}
                          <span>
                            [{yearMonthDiff(work.dateDebut, work.dateFin)}]
                          </span>
                        </div>
                        <div className="info-contrat">
                          {work.logo &&
                            <div className="logo">
                              <img src={urlFor(work.logo)} alt="logo" />
                            </div>
                          }
                          <div>
                            <h3 className="sous-title">
                              <span>{work.name} </span>
                              <span className="entreprise">
                                {work.company ? ` - ${work.company}` : ""}
                              </span>
                            </h3>
                            <div className="localisation">
                              <FaMapPin />
                              {work.localisation}
                            </div>
                          </div>
                        </div>

                        {work.desc.map((descByLine, index) => (
                          <p className="description" key={`description-${index}`}>
                            {descByLine}
                          </p>
                        ))}

                        <p className="competences">{work.competences}</p>
                      </motion.div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="block-setup">
              <img src={images.setup} alt="setup" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
