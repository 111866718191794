import email from "../assets/email.webp";
import mobile from "../assets/mobile.webp";
import node from "../assets/node.webp";
import react from "../assets/react.webp";
import php from "../assets/php.webp";
import profile from "../assets/profile.webp";
import logo from "../assets/logo.webp";
import circle from "../assets/circle.svg";
import setup from "../assets/setup.webp";
import spring from "../assets/spring.webp";
import java from "../assets/java.webp";
import javascript from "../assets/javascript.webp";
import css from "../assets/css.webp";
import angular from "../assets/angular.webp";
import typescript from "../assets/typescript.webp";

const images = {
  email,
  mobile,
  node,
  react,
  php,
  profile,
  logo,
  circle,
  setup,
  spring,
  java,
  javascript,
  css,
  angular,
  typescript
};

export default images;
