import React, { useState } from "react";

import "./ScrollProgressBar.scss";

const ScrollProgressBar = () => {
  const [scrollTop, setScrollTop] = useState(0);

  window.addEventListener("scroll", () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = (winScroll * 100) / height;

    setScrollTop(scrolled);
  });

  return (
    <div className="app__scroll">
      <div
        className="app__scroll-indicator"
        style={{ height: `${scrollTop}%` }}
      ></div>
    </div>
  );
};

export default ScrollProgressBar;
