import React, { useState } from "react";
import { motion } from "framer-motion";
import { BiCurrentLocation } from "react-icons/bi";
import { images } from "../../constants";
import "./Header.scss";

const Header = () => {
  const [animationPlayed, setAnimationPlayed] = useState(false);

  return (
    <div id="Accueil" className="app__header app__flex app__block">
      <motion.div
        initial={animationPlayed ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
        onAnimationStart={() => setAnimationPlayed(true)}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div style={{ marginLeft: 20 }}>
            <p className="app__header-badge-left">Salut, moi c'est</p>
            <h1>Kevin Duballet</h1>
            <p className="app__header-badge-right">Web Développeur</p>
            <div className="app__header-location-right">
              <BiCurrentLocation size={20} />
              <div
                className="city"
                aria-label="redirectCoordMontreal"
              >
                Montréal
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial={animationPlayed ? { scale: 1, opacity: 1 } : { scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1.5, delay: 0, ease: "easeInOut" }}
        className="app__header-img"
      >
        <img src={images.profile} alt="profile_bg" />
      </motion.div>

      <motion.div
        initial={animationPlayed ? { scale: 1, opacity: 1 } : { scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1, delay: 0.5, ease: "easeInOut" }}
        className="app__header-circles"
      >
        {[images.typescript, images.angular, images.java, images.css].map((circle, index) => (
          <div className="circle-cpm app__flex" key={`circle-${index}`}>
            <img src={circle} alt="circle" />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Header;
