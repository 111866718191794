import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { motion } from "framer-motion";
import { images } from "../../constants";
import "./Contact.scss";

const Contact = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [etatMail, setEtatMail] = useState(false);

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();

    emailjs
      .sendForm(
        "service_3evdvn9",
        "template_neojb8h",
        form.current,
        "ewmzmkvPafKkCbUVH"
      )
      .then(
        (result) => {
          setLoading(false);
          setEtatMail(true);
        },
        (error) => {
          setLoading(false);
          setEtatMail(false);
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <div id="Contact" className="padding-container app__block">
      <h2 className="head-text">Contact</h2>

      <div className="app__contact">
        <div className="app__contact-cards">
          <a
            href="mailto:kevin.duballet@gmail.com"
            className="p-text"
            aria-label="Open editor email"
          >
            <div className="app__contact-card">
              <img className="email" src={images.email} alt="email" />

              kevin.duballet@gmail.com
            </div>
          </a>

          {/* <div className="app__contact-card">
            <img className="phone" src={images.mobile} alt="phone" />
            <a
              href="tel:+33645531774"
              className="p-text"
              aria-label="Open interface to call"
            >
              06 45 53 17 74
            </a>
          </div> */}
        </div>
        {!etatMail ? (
          <form
            ref={form}
            onSubmit={sendEmail}
            className="app__contact-form app__flex"
          >
            <div className="app__flex">
              <input
                className="p-text"
                type="text"
                placeholder="Prénom/nom"
                name="user_name"
                required
                autoComplete="off"
              />
            </div>
            <div className="app__flex">
              <input
                className="p-text"
                type="email"
                placeholder="E-mail"
                name="user_email"
                required
                autoComplete="off"
              />
            </div>
            <div className="app__flex">
              <input
                className="p-text"
                type="text"
                placeholder="Sujet"
                name="subject"
                required
                autoComplete="off"
              />
            </div>
            <div>
              <textarea
                className="p-text"
                placeholder="Message"
                name="message"
                required
                autoComplete="off"
              />
            </div>
            <button type="submit" className="p-text">
              {!loading ? "Envoyer" : "Envoi en cours..."}
            </button>
          </form>
        ) : (
          <div>
            <h5 className="message-send">
              J'ai bien reçu le message, merci ! 😉
            </h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
